<template>
    <div class="doubleLine"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
          soprtList:[],
          serviceList:[]
        }
    },
    methods: {
        setChart() {
            let serviceMax = 0
            this.serviceList.forEach(el => {
                if(serviceMax<Number(el)){
                    serviceMax = Number(el)
                }
            });
            let soprtMax = 0
            this.soprtList.forEach(el => {
                if(soprtMax<Number(el)){
                    soprtMax = Number(el)
                }
            });
            // console.log(soprtMax,'场次最高');
            // console.log(serviceMax,'服务人数最高');
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    left: "11%",
                    top: "1%",
                    itemWidth: 7,
                    itemHeight: 7,
                    textStyle: {
                        color: '#5CB1C1',
                        fontSize: 10
                    }
                },
                grid: {
                    top: '10%',
                    left: '10%',
                    right: '10%',
                    bottom: '10%',
                    containLabel: false
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        symbol: ['none', 'arrow'],
                        symbolSize: [2,2],
                        symbolOffset: [0, 10],
                        lineStyle: {
                            color: '#122C49'
                        }
                    },
                    axisTick: {show: false},
                    axisLabel: {
                        color: '#61B9C8',
                        fontSize: 9
                    },
                    data: ["长沙市",'株洲市','湘潭市','衡阳市',"常德市",'益阳市','娄底市','郴州市',"永州市",'怀化市','湘西','张家界','岳阳市','邵阳市'],
                },
                yAxis: [
                      //第一个场次 第二个是人数
                    {
                        type: 'value',
                        scale: true,
                        max: Number(soprtMax > serviceMax?soprtMax:serviceMax)+100,
                        min: 0,
                        // interval: parseInt((Number(soprtMax > serviceMax?soprtMax:serviceMax)+500)/10),
                        axisLine: {
                            symbol: ['none', 'arrow'],
                            symbolSize: [6, 6],
                            lineStyle: {
                                color: '#122C49'
                            }
                        },
                        axisLabel: {
                            color: '#61B9C8',
                            showMaxLabel: false,
                            fontSize: 9
                        },
                        name: '(次)',
                        nameGap: -10,
                        nameTextStyle: {
                            color: '#61B9C8',
                            fontSize: 9,
                            align: 'right',
                            padding: [0, 0, 10, 0]
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        type: 'value',
                        scale: true,
                        max: Number(soprtMax > serviceMax?soprtMax:serviceMax)+500,
                        min: 0,
                        // interval: parseInt((Number(soprtMax > serviceMax?soprtMax:serviceMax)+500)/10),
                        axisLine: {
                            symbol: ['none', 'arrow'],
                            symbolSize: [6, 6],
                            lineStyle: {
                                color: '#122C49'
                            }
                        },
                        axisLabel: {
                            color: '#61B9C8',
                            showMaxLabel: false,
                            fontSize: 9
                        },
                        name: '(人)',
                        nameGap: -10,
                        nameTextStyle: {
                            color: '#61B9C8',
                            fontSize: 9,
                            align: 'left',
                            padding: [0, 0, 15, 6]
                        },
                        splitLine: {
                            show: false,
                        },
                    }
                ],
                series: [
                    {
                        name: '活动场次',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            color: '#F39800',
                        },
                        itemStyle: {
                            color: '#F39800'
                        },
                        data: this.soprtList
                    },
                    {
                        name: '服务人次',
                        yAxisIndex: 1,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            color: '#BF232A',
                        },
                        itemStyle: {
                            color: '#BF232A'
                        },
                      data: this.serviceList
                    },
                ]
            };
           
          
           
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.doubleLine {
    height: 100%;
    width: 100%;
}
</style>